// colour variants
$col-white: #fff;
$col-black: #000;
$col-black-2: #0e1e15;

$col-green: #376c65;
$col-green-2: #3d5b57;
$col-green-3: #8eccc4;

$col-orange: #ead8a0;
$col-orange-2: #ffdf8b;
$col-orange-3: #fffdf5;
$col-orange-4: #f2e1b7;

// font sizes
$font-hero: clamp(2.013rem, 3vw + 1rem, 4.813rem);
$font-sub: clamp(1.2rem, 3vw + 1rem, 1.45rem);
$font-sub-2: clamp(1rem, 3vw + 1rem, 1rem);
$font-button: clamp(1.25rem, 3vw + 1rem, 1.438rem);
$font-body: clamp(1rem, 3vw + 1rem, 1.25rem);
$font-section-header: clamp(2.025rem, 3vw + 1rem, 3rem);
$font-section-header-2: clamp(1.725rem, 3vw + 1rem, 2.75rem);
$font-section-body: clamp(1.25rem, 3vw + 1rem, 1.45rem);
$font-section-body-2: clamp(1rem, 3vw + 1rem, 1.25rem);
$font-guest-site-header: clamp(1.275rem, 3vw + 1rem, 3.813rem);
$font-footer-header: clamp(1.275rem, 3vw + 1rem, 4rem);
$font-footer-text: clamp(0.9rem, 3vw + 1rem, 1.15rem);
$font-footer-text-2: clamp(0.8rem, 3vw + 1rem, 0.95rem);

// default font
$font: "Helvetica", "sans-serif";

// container sizes
$max-lg: 1500px;
$max-sm: 300px;
$max-md: 1200px;

$padding-lg: 1.875rem;

$radius-sm: 0.1625rem;
