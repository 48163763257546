// Abstract
@use "./abstracts/mixins" as *;
@use "./abstracts/tokens" as *;
@use "./abstracts/variables" as *;

//Base
@use "./base/reset" as *;
@use "./base/typography" as *;

.prelaunch__video-content {
  min-width: 100%;
  height: 100%;

  @include respond(big-tab) {
    max-width: 100%;
  }
}

.prelaunch__loading {
  background-color: #253d39;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.prelaunch__loading-dots {
  width: 5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.prelaunch__loading-dots div {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #fff;
  animation: fade 0.8s ease-in-out alternate infinite;
}

.prelaunch__loading-dots div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.prelaunch__loading-dots div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
